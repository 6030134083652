import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';

interface TextEditorProps {
    onChange: (content: string) => void;
    initialValue?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ initialValue = '', onChange }) => {
    const [editorValue, setEditorValue] = useState<string>(initialValue);

    const modules = {
        toolbar: [
            [{ 'size': ['small', 'large', 'huge', false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
        ],
    };

    const formats = [
        'size', 'bold', 'italic', 'underline', 'strike',
        'align', 'list', 'bullet', 'link', 'image',
        'color', 'background'
    ];

    const onchangeEditor = (value: string) => {
        setEditorValue(value);
        onChange(value);

    };

    return (
        <div className="custom-editor-container">
            <ReactQuill
                value={editorValue}
                onChange={onchangeEditor}
                modules={modules}
                formats={formats}
                theme="snow"
                className="custom-editor auto-growing-editor"
            />
        </div>
    );
};

export default TextEditor;
