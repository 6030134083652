import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Upload, Icon, message, Transfer } from 'antd';
import { OfficeFormProps } from './Types';
import { t, Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import { getDirectoryTenantId, i18n } from '../../utils';
import { hideOfficeForm, setOfficeLogo } from '../../store/offices';
import "./OfficeForm.css";
import { loadEnabledProviderOffices } from '../../services/provider';
import { ProviderState } from '../../store/provider';
import TextEditor from '../text-editor/TextEditor';

const OfficeForm: React.FC<OfficeFormProps> = ({ office, form, onSubmit, isLoading, formType }) => {
    const dispatch = useDispatch();
    const { providerState } = useSelector(mapState);
    const { providerOffices } = providerState;
    const { getFieldDecorator, validateFields, setFieldsValue } = form;

    const providersData = (providerOffices ?? []).map((x) => ({
        key: `${x.ProviderID}/${x.AgendaID}/${x.LocationID}`,
        title: x.FullName,
    }));

    const linkedProviders = office && office.Providers
        ? office?.Providers?.map(
            (x) => `${x.ProviderID}/${x.AgendaID}/${x.LocationID}`
        )
        : [];

    useEffect(() => {
        loadEnabledProviderOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            back();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                const request = {
                    ...values,
                    OfficeID: office?.OfficeID,
                    DirectoryTenantID: formType === "create" ? getDirectoryTenantId() : office?.DirectoryTenantID,
                    UrlLogo: String(office?.UrlLogo),
                    Providers: values.Providers.map((x: string) => {
                        let result = x.split("/");
                        return {
                            ProviderID: result[0],
                            AgendaID: result[1],
                            LocationID: result[2],
                        };
                    })
                };

                onSubmit(request);
            }
        });
    };

    const beforeLogoUpload = (_: any) => {
        return false;
    };

    const getBase64 = (img: any, callback: (result: any) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleLogoChange = (info: any) => {
        const isJpgOrPng = info.file.type === "image/jpeg" || info.file.type === "image/png";
        const isLt2M = info.file.size / 1024 / 1024 < 2;

        if (!isJpgOrPng) {
            message.error(
                i18n._(
                    t("Office.OnlyImagesAllowedError")`You can only upload JPG/PNG file!`
                )
            );
            return;
        }

        if (!isLt2M) {
            message.error(
                i18n._(
                    t("Office.FileMustBeLessThan2MBError")`Image must smaller than 2MB!`
                )
            );
            return;
        }

        getBase64(info.file, (url) => {
            dispatch(setOfficeLogo(url));

            setFieldsValue({ UrlLogo: url });
        });
    };

    const handleBodyChange = (value: string) => {
        setFieldsValue({ Body: value });
    };

    const back = () => {
        dispatch(hideOfficeForm());
    };

    const filterOption = (inputValue: any, data: any) =>
        data.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;


    return (
        <Row type="flex" justify="center">
            <Col xs={24} sm={18} md={12} lg={12}>
                <h2 style={{ textAlign: "center" }}>
                    {formType === 'edit' ? (
                        <Trans render="span" id="OfficeForm.EditTitle">Edit Office</Trans>
                    ) : (
                        <Trans render="span" id="OfficeForm.CreateTitle">Create Office</Trans>
                    )}
                </h2>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>

                            <Form.Item label={<Trans id="Office.Form.Logo">Logo</Trans>}>
                                {getFieldDecorator("UrlLogo", {
                                    valuePropName: "fileList",
                                    initialValue: office?.UrlLogo
                                        ? office.UrlLogo !== ""
                                            ? [{}]
                                            : undefined
                                        : undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: <Trans id="Common.RequiredFieldError" />
                                        },
                                    ],
                                })(
                                    <Upload
                                        className="form-office-logo"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeLogoUpload}
                                        onChange={(e) => handleLogoChange(e)}
                                    >
                                        {office?.UrlLogo !== "" ? (
                                            <img src={office?.UrlLogo} alt="Office logo" />
                                        ) : (
                                            <div>
                                                <Icon type="plus" />
                                                <Trans render="div" id="Office.Form.LogoLabel">
                                                    Add logo
                                                </Trans>
                                            </div>
                                        )}
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={<Trans render="span" id="OfficeForm.NameLabel">Name</Trans>}
                            >
                                {getFieldDecorator("Name", {
                                    rules: [{ required: true, message: <Trans id="Common.RequiredFieldError" /> }],
                                    initialValue: office?.Name,
                                })(<Input maxLength={128} />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label={<Trans render="span" id="OfficeForm.LocationNameLabel">Location Name</Trans>}>
                                {getFieldDecorator("LocationName", {
                                    initialValue: office?.LocationName,
                                })(<Input maxLength={128} />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<Trans render="span" id="OfficeForm.PhoneLabel">Phone</Trans>}>
                                {getFieldDecorator("Phone", {
                                    initialValue: office?.Phone,
                                })(<Input maxLength={25} />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label={<Trans render="span" id="OfficeForm.LatitudeLabel">Latitude</Trans>}>
                                {getFieldDecorator("Latitude", {
                                    rules: [{ required: true, message: <Trans id="Common.RequiredFieldError" /> }],
                                    initialValue: office?.Latitude ?? 0,
                                })(<Input type="number" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<Trans render="span" id="OfficeForm.LongitudeLabel">Longitude</Trans>}>
                                {getFieldDecorator("Longitude", {
                                    rules: [{ required: true, message: <Trans id="Common.RequiredFieldError" /> }],
                                    initialValue: office?.Longitude ?? 0,
                                })(<Input type="number" />)}
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={<Trans render="span" id="OfficeForm.BodyLabel">Body</Trans>}
                            >
                                {getFieldDecorator("Body", {
                                    initialValue: office?.Body,
                                })(<TextEditor
                                    initialValue={office?.Body}
                                    onChange={(value) => handleBodyChange(value)}
                                />)}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={<Trans id="Office.Form.Providers">Providers to associate</Trans>}>
                                {getFieldDecorator("Providers", {
                                    valuePropName: "targetKeys",
                                    initialValue: linkedProviders,
                                })(
                                    <Transfer
                                        className="transfer"
                                        dataSource={providersData}
                                        showSearch
                                        filterOption={filterOption}
                                        render={(item) => item.title || ""}
                                        style={{ textAlign: "center" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item style={{ marginTop: "15px" }}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            {formType === 'edit' ? (
                                <Trans render="span" id="OfficeForm.UpdateButton">Update</Trans>
                            ) : (
                                <Trans render="span" id="OfficeForm.CreateButton">Create</Trans>
                            )}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="default"
                            style={{ width: '100%' }}
                            onClick={back}
                            loading={isLoading}
                        >
                            <Trans render="span" id="Common.Back">Back</Trans>
                        </Button>
                    </Form.Item>

                </Form>
            </Col>
        </Row>
    );
};
const mapState = (state: any): State => ({
    providerState: state.providerState,
});

interface State {
    providerState: ProviderState;
}
export default Form.create<OfficeFormProps>()(OfficeForm);
