import React, { useEffect } from "react";
import "./OfficesPage.css";
import { useSelector, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import {
  Tooltip,
  Button,
  Divider,
  Popconfirm,
  Row,
  Table,
  message,
  Typography,
  Col,
  Input,
  Avatar,
} from "antd";
import { Container } from "../../components/utils";
import { i18n } from "../../utils";
import { createOffice, deleteOffice, loadOffices, updateOffice } from "../../services/offices/OfficeService";
import { filterOffices, Office, OfficeState, showOfficeFormForCreate, showOfficeFormForEdit } from "../../store/offices";
import { OfficeForm } from "../../components/office-form";

export const OfficesPage: React.FC = () => {
  const pageSize = 20;
  const officeState = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const { reloading, isLoading, formType, showForm, filteredOffices, count, office } = officeState;

  useEffect(() => {
    loadOffices();
  }, [reloading]);

  const onCreateOffice = () => {
    dispatch(showOfficeFormForCreate());
  };

  const onSubmit = (item: Office) => {
    if (formType === "create") createOffice(item);
    if (formType === "edit") updateOffice(item);
  }

  const onDeleteOffice = (officeID: string) => {
    message.loading(
      i18n._(t("Common.DeletingEntity")`Deleting item...`),
      0.5,
      () => deleteOffice(officeID)
    );
  };

  const onUpdateOffice = (item: Office) => {
    dispatch(showOfficeFormForEdit(item));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Office.UrlLogo">
          Logo
        </Trans>
      ),
      key: "Logo",
      render: (office: Office) => (
        <span>
          <Tooltip title={office.UrlLogo}>
            <Avatar shape="square" size="large" src={office.UrlLogo} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: (
        <Trans render="strong" id="Office.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="Common.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (item: Office) => (
        <span>
          <Tooltip
            title={<Trans id="Common.EditActionTooltip">Edit item</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateOffice(item)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Common.DeleteActionTooltip">Delete item</Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Common.DeleteActionConfirmationText">
                  Do you wish to delete this item?
                </Trans>
              }
              okText={<Trans id="Landings.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteOffice(item.OfficeID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
      width: 150,
    },
  ];
  const filterFoundOffices = (value: string) => {
    dispatch(filterOffices(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundOffices(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        {!showForm &&
          <div className="offices-page">
            <Typography.Title level={2}>
              <Trans id="OfficePage.Title">Offices</Trans>
            </Typography.Title>
            <Table
              className="offices-table"
              dataSource={filteredOffices}
              columns={columns}
              loading={isLoading}
              bodyStyle={{ background: "#fff" }}
              rowKey="OfficeID"
              pagination={
                count > pageSize && {
                  pageSize,
                  position: "bottom",
                }
              }
              title={() => (
                <Row type="flex" align="middle">
                  <Col span={6}>
                    <Input
                      onChange={onChange}
                      placeholder={i18n._(
                        t("Offices.FilterOfficesPlaceholder")`Search offices`
                      )}
                    />
                  </Col>
                  <Col span={18}>
                    <Row type="flex" justify="end">
                      <Button
                        type="primary"
                        size="large"
                        onClick={onCreateOffice}
                      >
                        <Trans id="Offices.CreateButton">New offices</Trans>
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            />
          </div>
        }

        {showForm &&
          <OfficeForm
            office={office}
            isLoading={isLoading}
            formType={formType}
            onSubmit={onSubmit}
          />
        }
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): OfficeState => state.officeState;
