import { hideLandingForm, reloading, setLanding } from './../../store/landing/Actions';
import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { Landing, SetIsLoading as setIsLoading, setLandings } from "../../store/landing";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";


export const loadLandings = async () => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/landing/${getDirectoryTenantId()}`;

    try {
        store.dispatch(setIsLoading(true));
        const response = await httpClient.get<GenericApiResponse<Landing[]>>(url);
        store.dispatch(setLandings(response.data.Data));
        store.dispatch(setIsLoading(false));
        return true;
    } catch {
        store.dispatch(setLandings([]));
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const loadLanding = async (landingID: string) => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/landing/${landingID}`;

    try {
        store.dispatch(setIsLoading(true));
        const response = await httpClient.get<GenericApiResponse<Landing>>(url);
        store.dispatch(setLanding(response.data.Data));
        store.dispatch(setIsLoading(false));
        return true;
    } catch {
        store.dispatch(setLanding(undefined));
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const createLanding = async (request: Landing) => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/landing`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.post(url, request);
        store.dispatch(setIsLoading(false));
        store.dispatch(hideLandingForm());
        store.dispatch(reloading());
        return true;
    } catch (message) {
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const updateLanding = async (request: Landing) => {
    const { LandingID, ...rest } = request;
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/landing/${LandingID}`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.put(url, rest);
        store.dispatch(setIsLoading(false));
        store.dispatch(hideLandingForm());
        store.dispatch(reloading());
        return true;
    } catch (message) {
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const deleteLanding = async (landingID: string) => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/landing/${landingID}`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.delete(url);
        store.dispatch(setIsLoading(false));
        store.dispatch(reloading());
        return true;
    } catch (message) {
        notification.error({
            message: i18n._(
                t("Common.CouldNotDeleteItemErrorTitle")`Iten not deleted`
            ),
            description: i18n._(
                t(
                    "Common.CouldNotDeleteItemErrorDescription"
                )`The item could not be deleted.`
            ),
        });
        store.dispatch(setIsLoading(false));
        return false;
    }
};
