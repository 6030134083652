import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { hideOfficeForm, Office, reloading, setIsLoading, setLandingOffices, setOffice, setOffices } from "../../store/offices";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadOffices = async () => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office/${getDirectoryTenantId()}`;

    try {
        store.dispatch(setIsLoading(true));
        const response = await httpClient.get<GenericApiResponse<Office[]>>(url);
        store.dispatch(setOffices(response.data.Data));
        store.dispatch(setIsLoading(false));
        return true;
    } catch {
        store.dispatch(setOffices([]));
        store.dispatch(setIsLoading(false));
        return false;
    }
};


export const loadLandingOffices = async () => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office/${getDirectoryTenantId()}`;

    try {
        const response = await httpClient.get<GenericApiResponse<Office[]>>(url);
        store.dispatch(setLandingOffices(response.data.Data));
        return true;
    } catch {
        store.dispatch(setLandingOffices([]));
        return false;
    }
};


export const loadOffice = async (officeID: string) => {

    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office/${officeID}`;

    try {
        store.dispatch(setIsLoading(true));
        const response = await httpClient.get<GenericApiResponse<Office>>(url);
        store.dispatch(setOffice(response.data.Data));
        store.dispatch(setIsLoading(false));
        return true;
    } catch {
        store.dispatch(setOffice(undefined));
        store.dispatch(setIsLoading(false));
        return false;
    }
};


export const createOffice = async (request: Office) => {

    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.post(url, request);
        store.dispatch(setIsLoading(false));
        store.dispatch(hideOfficeForm());
        store.dispatch(reloading());
        return true;
    } catch (message) {
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const updateOffice = async (request: Office) => {
    const { OfficeID } = request;
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office/${OfficeID}`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.put(url, request);
        store.dispatch(setIsLoading(false));
        store.dispatch(hideOfficeForm());
        store.dispatch(reloading());
        return true;
    } catch (message) {
        store.dispatch(setIsLoading(false));
        return false;
    }
};

export const deleteOffice = async (officeID: string) => {
    const url = `${localStorage.getItem(LocalStorageKeys.baseApiUrl)}admin/office/${officeID}`;

    try {
        store.dispatch(setIsLoading(true));
        await httpClient.delete(url);
        store.dispatch(setIsLoading(false));
        store.dispatch(reloading());
        return true;
    } catch (message) {
        notification.error({
            message: i18n._(
                t("Common.CouldNotDeleteItemErrorTitle")`Iten not deleted`
            ),
            description: i18n._(
                t(
                    "Common.CouldNotDeleteItemErrorDescription"
                )`The item could not be deleted.`
            ),
        });
        store.dispatch(setIsLoading(false));
        return false;
    }
};
