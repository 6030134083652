import React, { useEffect } from "react";
import "./LandingsPage.css";
import { useSelector, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import {
  Tooltip,
  Button,
  Divider,
  Popconfirm,
  Row,
  Table,
  message,
  Typography,
  Col,
  Input,
} from "antd";
import { Container, YesNoCell } from "../../components/utils";
import { i18n } from "../../utils";
import { createLanding, deleteLanding, loadLandings, updateLanding } from "../../services/landing/LandingService";
import { filterLandings, Landing, LandingState, showLandingFormForCreate, showLandingFormForEdit } from "../../store/landing";
import { LandingForm } from "../../components/landing-form";

export const LandingsPage: React.FC = () => {
  const pageSize = 20;
  const landingState = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const { reloading, landing, isLoading, formType, showForm, count, filteredLandings } = landingState;

  useEffect(() => {
    loadLandings();
  }, [reloading]);

  const onCreateLanding = () => {
    dispatch(showLandingFormForCreate());
  };

  const onSubmit = (item: Landing) => {
    if (formType === "create") createLanding(item);
    if (formType === "edit") updateLanding(item);
  }

  const onDeleteLanding = (landingID: string) => {
    message.loading(
      i18n._(t("Common.DeletingEntity")`Deleting item...`),
      0.5,
      () => deleteLanding(landingID)
    );
  };

  const onUpdateLanding = (item: Landing) => {
    dispatch(showLandingFormForEdit(item));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Landings.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="Landings.UrlSlugColumnTitle">
          UrlSlug
        </Trans>
      ),
      dataIndex: "UrlSlug",
      key: "UrlSlug",
    },
    {
      title: (
        <Trans render="strong" id="Landings.IsDeleted">
          Is deleted
        </Trans>
      ),
      dataIndex: "IsDeleted",
      key: "IsDeleted",
      render: (isDeleted: boolean) => <YesNoCell value={!isDeleted} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Landings.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (landing: Landing) => (
        <span>
          <Tooltip
            title={<Trans id="Common.EditActionTooltip">Edit item</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateLanding(landing)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Common.DeleteActionTooltip">Delete item</Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Common.DeleteActionConfirmationText">
                  Do you wish to delete this item?
                </Trans>
              }
              okText={<Trans id="Landings.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteLanding(landing.LandingID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
      width: 150,
    },
  ];
  const filterFoundLandings = (value: string) => {
    dispatch(filterLandings(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundLandings(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        {!showForm &&
          <div className="landings-page">
            <Typography.Title level={2}>
              <Trans id="LandingsPage.Title">Landings</Trans>
            </Typography.Title>
            <Table
              className="landings-table"
              dataSource={filteredLandings}
              columns={columns}
              loading={isLoading}
              bodyStyle={{ background: "#fff" }}
              rowKey="LandingID"
              pagination={
                count > pageSize && {
                  pageSize,
                  position: "bottom",
                }
              }
              title={() => (
                <Row type="flex" align="middle">
                  <Col span={6}>
                    <Input
                      onChange={onChange}
                      placeholder={i18n._(
                        t("Landings.FilterLandingsPlaceholder")`Search landings`
                      )}
                    />
                  </Col>
                  <Col span={18}>
                    <Row type="flex" justify="end">
                      <Button
                        type="primary"
                        size="large"
                        onClick={onCreateLanding}
                      >
                        <Trans id="Landings.CreateButton">New Landing</Trans>
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            />
          </div>
        }

        {showForm &&
          <LandingForm
            landing={landing}
            isLoading={isLoading}
            formType={formType}
            onSubmit={onSubmit}
          />
        }
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): LandingState => state.landingState;
