import React, { useEffect } from "react";
import "./ClinicModal.css";
import {
  Form,
  Modal,
  Row,
  Upload,
  Icon,
  Input,
  message,
  Transfer,
  Col,
  Button,
  Checkbox,
} from "antd";
import { ClinicModalProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import { hideClinicModal, Clinic, setClinicLogo, setClinicBanner } from "../../store/clinic";
import { nameofFactory, i18n } from "../../utils";
import { Trans, t } from "@lingui/macro";
import TextArea from "antd/lib/input/TextArea";
import { loadClinic } from "../../services/clinic";
import { ProviderState } from "../../store/provider";
import { SpecialtyState } from "../../store/specialty";
import { loadEnabledProvidersClinic } from "../../services/provider";
import { loadSpecialtiesTypeahead } from "../../services/specialty";
import { Link } from "react-router-dom";

const ClinicModal: React.FC<ClinicModalProps> = (props) => {
  const { providerState, specialtyState } = useSelector(mapState);
  const dispatch = useDispatch();
  const { clinic, form, loading, modalType, onCreate, onUpdate, showModal } =
    props;
  const { getFieldDecorator, validateFields } = form;
  const nameof = nameofFactory<Clinic>();

  useEffect(() => {
    loadEnabledProvidersClinic();
    loadSpecialtiesTypeahead();
    if (clinic?.ClinicID) {
      loadClinic(clinic.ClinicID);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic?.ClinicID]);

  const beforeLogoUpload = (_: any) => {
    return false;
  };

  const getBase64 = (img: any, callback: (result: any) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoChange = (info: any, isLogo: boolean) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.OnlyImagesAllowedError"
          )`You can only upload JPG/PNG file!`
        )
      );
    }

    const isLt2M = info.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.FileMustBeLessThan2MBError"
          )`  Image must smaller than 2MB!`
        )
      );
    }

    if (isJpgOrPng && isLt2M) {
      getBase64(info.file, (url) => {
        if (isLogo) dispatch(setClinicLogo(url));
        if (!isLogo) dispatch(setClinicBanner(url));
      });
    }
  };

  const onModalDismiss = () => {
    dispatch(hideClinicModal());
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const clinicRequest = {
        ...values,
        Providers: values.Providers.map((x: string) => {
          let result = x.split("/");
          return {
            ProviderID: result[0],
            AgendaID: result[1],
            LocationID: result[2],
          };
        }),
        Specialties: values.Specialties.map((x: number) => ({
          SpecialtyID: Number(x),
        })),
        URLLogo: String(clinic?.URLLogo),
        ClinicID: Number(clinic?.ClinicID),
        BannerURL: String(clinic?.BannerURL)
      };

      if (modalType === "create") onCreate(clinicRequest);
      else onUpdate(clinicRequest);

    });
  };

  const data =
    providerState.clinicProviders.map((x) => ({
      key: `${x.ProviderID}/${x.AgendaID}/${x.LocationID}`,
      title: x.FullName,
    })) || [];

  const dataSpecialties =
    specialtyState.specialtiesTypeahead.map((x) => ({
      key: x.SpecialtyID.toString(),
      title: x.Name,
    })) || [];

  const targetKeys =
    clinic?.Providers?.map(
      (x) => `${x.ProviderID}/${x.AgendaID}/${x.LocationID}`
    ) || [];
    
  const targetKeysSpecialties =
    clinic?.Specialties?.map((x) => x.SpecialtyID.toString()) || [];

  const filterOption = (inputValue: any, data: any) =>
    data.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;

  return (
    <Modal
      centered
      className="clinic-modal"
      destroyOnClose
      visible={showModal}
      footer={
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button loading={loading} onClick={onSubmit} type="primary">
              {modalType === "create" ? (
                <Trans render="span" id="ClinicModal.CreateButton">
                  Create
                </Trans>
              ) : (
                <Trans render="span" id="ClinicModal.UpdateButton">
                  Update
                </Trans>
              )}
            </Button>
          </Col>
          <Col>
            <Button disabled={loading} onClick={onModalDismiss}>
              <Trans render="span" id="ClinicModal.CancelButton">
                Cancel
              </Trans>
            </Button>
          </Col>
        </Row>
      }
      onCancel={onModalDismiss}
      title={
        modalType === "create" ? (
          <Trans id="ClinicModal.CreateTitle">Create clinic</Trans>
        ) : (
          <Trans id="ClinicModal.EditTitle">Edit clinic</Trans>
        )
      }
    >
      <Form colon={false}>
        <Row type="flex" justify="center" align="middle">
          <Form.Item label={<Trans id="Clinics.Form.Logo">Logo</Trans>}>
            {getFieldDecorator(nameof("URLLogo"), {
              valuePropName: "fileList",
              initialValue: clinic?.URLLogo
                ? clinic.URLLogo !== ""
                  ? [{}]
                  : undefined
                : undefined,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Clinics.Form.LogoRequiredError">
                      Logo is required
                    </Trans>
                  ),
                },
              ],
            })(
              <Upload
                className="form-clinic-logo"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeLogoUpload}
                onChange={(e) => handleLogoChange(e, true)}
              >
                {clinic?.URLLogo !== "" ? (
                  <img src={clinic?.URLLogo} alt="Clinic logo" />
                ) : (
                  <div>
                    <Icon type="plus" />
                    <Trans render="div" id="Clinics.Form.LogoLabel">
                      Add logo
                    </Trans>
                  </div>
                )}
              </Upload>
            )}
          </Form.Item>
        </Row>
        <Row type="flex" justify="center" align="middle" >
          <Col span={24} style={{ maxWidth: "50%" }}>
            <Form.Item label={<Trans id="Clinics.Form.Banner">Banner</Trans>}
              help={
                <span style={{ color: "black" }}><Icon type="info-circle" theme="twoTone" />{" "}
                  <Trans id="Clinics.Form.BannerDimensions">recommended size 1500 x 400</Trans>
                </span>
              }>
              {getFieldDecorator(nameof("BannerURL"), {
                valuePropName: "fileList",
                initialValue: clinic?.BannerURL
                  ? clinic.BannerURL !== ""
                    ? [{}]
                    : undefined
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Clinics.Form.BannerRequiredError">
                        Banner is required
                      </Trans>
                    ),
                  },
                ],
              })(
                <Upload
                  className="form-clinic-BannerURL"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeLogoUpload}
                  onChange={(e) => handleLogoChange(e, false)}
                >
                  {clinic?.BannerURL !== "" ? (
                    <img src={clinic?.BannerURL} alt="Clinic banner" />
                  ) : (
                    <div>
                      <Icon type="plus" />
                      <Trans render="div" id="Clinics.Form.BannerLabel">
                        Add banner
                      </Trans>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item >
          </Col>
        </Row>

        <Row>
          {modalType === "edit" &&
            <Row>
              <Link
                to={{
                  pathname: clinic?.LinkSlug,
                }}
                target="_blank"
              >
                <Trans id="Clinics.Form.ViewProfileLink">
                  View public profile
                </Trans>
              </Link>
            </Row>
          }

          <Col span={24} >
            <Form.Item label={<Trans id="Clinics.Form.NameLabel">Name</Trans>}>
              {getFieldDecorator(nameof("Name"), {
                initialValue: clinic?.Name,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Clinics.Form.NameRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<Trans id="Clinics.Form.UrlSlugLabel">UrlSlug</Trans>}
        >
          {getFieldDecorator(nameof("UrlSlug"), {
            initialValue: clinic?.UrlSlug,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="Clinics.Form.UrlSlugRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<Input maxLength={60} />)}
        </Form.Item>
        <Form.Item
          label={<Trans id="Clinics.Form.SID">Suscription ID</Trans>}
        >
          {getFieldDecorator(nameof("SID"), {
            initialValue: clinic?.SID,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="Common.RequiredFieldError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<Input maxLength={60} />)}
        </Form.Item>

        <Form.Item label={<Trans id="Clinics.Form.AboutLabel">About</Trans>}>
          {getFieldDecorator(nameof("About"), {
            initialValue: clinic?.About,
            rules: [
              {
                required: false,
                message: (
                  <Trans id="Clinics.Form.AboutRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<TextArea />)}
        </Form.Item>
        <Row>
          <Col span={7}>
            <Form.Item
              label={<Trans id="Clinics.Form.LattitudeLabel">Lattitude</Trans>}
            >
              {getFieldDecorator(nameof("Lattitude"), {
                initialValue: clinic?.Lattitude,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Clinics.Form.LattitudeRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={10} />)}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={7}>
            <Form.Item
              label={<Trans id="Clinics.Form.LongitudeLabel">Longitude</Trans>}
            >
              {getFieldDecorator(nameof("Longitude"), {
                initialValue: clinic?.Longitude,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Clinics.Form.LongitudeRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={10} />)}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={7}>
            <Form.Item
              label={<Trans id="Clinics.Form.PhoneLabel">Phone</Trans>}
            >
              {getFieldDecorator(nameof("Phone"), {
                initialValue: clinic?.Phone,
              })(<Input maxLength={20} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={<Trans id="Clinics.Form.IsPrivate">Is private</Trans>}
            >
              {getFieldDecorator(nameof("IsPrivate"), {
                initialValue: clinic?.IsPrivate === true ? true : false,
                valuePropName:
                  clinic?.IsPrivate === true ? "checked" : "unchecked",
              })(<Checkbox></Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<Trans id="Clinics.Form.IsMain">Is main</Trans>}>
              {getFieldDecorator(nameof("IsMain"), {
                initialValue: clinic?.IsMain === true ? true : false,
                valuePropName:
                  clinic?.IsMain === true ? "checked" : "unchecked",
              })(<Checkbox></Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<Trans id="Clinics.Form.IsDeleted">Is deleted</Trans>}>
              {getFieldDecorator(nameof("IsDeleted"), {
                initialValue: clinic?.IsDeleted,
                valuePropName:
                  clinic?.IsDeleted ? "checked" : "unchecked",
              })(<Checkbox></Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Trans id="Clinics.Form.AddressLabel">Address</Trans>}
            >
              {getFieldDecorator(nameof("Address"), {
                initialValue: clinic?.Address,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Clinics.Form.AddressRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<TextArea maxLength={500} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={<Trans id="Clinics.Form.Providers">Providers to associate</Trans>}>
              {getFieldDecorator("Providers", {
                valuePropName: "targetKeys",
                initialValue: targetKeys,
              })(
                <Transfer
                  className="transfer"
                  dataSource={data}
                  showSearch
                  filterOption={filterOption}
                  render={(item) => item.title || ""}
                  style={{ textAlign: "center" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={<Trans id="Clinics.Form.Specialties">Specialties to associate</Trans>}>
              {getFieldDecorator("Specialties", {
                valuePropName: "targetKeys",
                initialValue: targetKeysSpecialties,
              })(
                <Transfer
                  className="transfer"
                  dataSource={dataSpecialties}
                  showSearch
                  filterOption={filterOption}
                  render={(item) => item.title || ""}
                  style={{ textAlign: "center" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapState = (state: any): State => ({
  providerState: state.providerState,
  specialtyState: state.specialtyState,
});

interface State {
  providerState: ProviderState;
  specialtyState: SpecialtyState;
}
export default Form.create<ClinicModalProps>()(ClinicModal);
