import { Landing } from "./Types";

export const SET_LANDING = "SET_LANDING";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_LANDINGS = "SET_LANDINGS";
export const HIDE_LANDING_FORM = "HIDE_LANDING_FORM";
export const SHOW_LANDING_FORM_FOR_CREATE = "SHOW_LANDING_FORM_FOR_CREATE";
export const SHOW_LANDING_FORM_FOR_EDIT = "SHOW_LANDING_FORM_FOR_EDIT";
export const FILTER_LANDINGS = "FILTER_LANDINGS";
export const SET_RELOADING = "SET_RELOADING";

export const SetIsLoading = (isLoading: boolean) => ({
    type: SET_IS_LOADING,
    isLoading
});

export const hideLandingForm = () => ({ type: HIDE_LANDING_FORM });

export const setLanding = (Landing: Landing | undefined) => ({
    type: SET_LANDING,
    Landing,
});

export const setLandings = (Landings: Landing[]) => ({
    type: SET_LANDINGS,
    Landings,
});

export const showLandingFormForCreate = () => ({
    type: SHOW_LANDING_FORM_FOR_CREATE,
});

export const showLandingFormForEdit = (Landing: Landing) => ({
    type: SHOW_LANDING_FORM_FOR_EDIT,
    Landing,
});

export const filterLandings = (value: string) => ({
    type: FILTER_LANDINGS,
    value,
});

export const reloading = () => ({
    type: SET_RELOADING,
});