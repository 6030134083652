import { Office, OfficeState } from "./Types";
import {
    SET_OFFICE,
    SET_IS_LOADING,
    SET_OFFICES,
    HIDE_OFFICE_FORM,
    SHOW_OFFICE_FORM_FOR_CREATE,
    SHOW_OFFICE_FORM_FOR_EDIT,
    FILTER_OFFICES,
    SET_RELOADING,
    SET_LANDING_OFFICES,
    SET_OFFICE_LOGO,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: OfficeState = {
    office: undefined,
    offices: [],
    count: 0,
    isLoading: false,
    formType: "create",
    showForm: false,
    filteredOffices: [],
    landingOffices: [],
    reloading: false

};

export const officesReducer = (state = initialState, action: any): OfficeState => {
    switch (action.type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_OFFICE:
            return {
                ...state,
                office: action.offices,
            };
        case SET_OFFICES:
            return {
                ...state,
                offices: action.offices,
                count: action.offices.length,
                filteredOffices: action.offices,
            };
        case CHANGE_DIRECTORY:
        case SET_RELOADING:
            return {
                ...state,
                office: undefined,
                offices: [],
                landingOffices: [],
                count: 0,
                reloading: !state.reloading,
            };
        case SHOW_OFFICE_FORM_FOR_CREATE:
            return {
                ...state,
                formType: "create",
                showForm: true,
            };
        case SHOW_OFFICE_FORM_FOR_EDIT:
            return {
                ...state,
                formType: "edit",
                showForm: true,
                office: action.office,
            };
        case HIDE_OFFICE_FORM:
            return {
                ...state,
                office: undefined,
                showForm: false
            };
        case FILTER_OFFICES:
            return {
                ...state,
                filteredOffices: filterLandings(action.value, state.offices),
            };
        case SET_LANDING_OFFICES:
            return {
                ...state,
                landingOffices: action.offices,
            };
        case SET_OFFICE_LOGO:
            return {
                ...state,
                office: {
                    ...(state.office as Office),
                    UrlLogo: action.urlLogo,
                },
            };
        default:
            return state;
    }
};


const filterLandings = (value: string, officess: Office[]) => {
    if (value === "") return officess;

    return officess.filter((item) => isFilterMatch(value, item));
};

const isFilterMatch = (value: string, offices: Office) => {
    return offices.Name.toLowerCase().includes(value.toLowerCase());
};
