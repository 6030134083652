import { Landing, LandingState } from "./Types";
import {
    SET_LANDING,
    SET_IS_LOADING,
    SET_LANDINGS,
    HIDE_LANDING_FORM,
    SHOW_LANDING_FORM_FOR_CREATE,
    SHOW_LANDING_FORM_FOR_EDIT,
    FILTER_LANDINGS,
    SET_RELOADING,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: LandingState = {
    landing: undefined,
    landings: [],
    count: 0,
    isLoading: false,
    formType: "create",
    showForm: false,
    filteredLandings: [],
    reloading: false

};

export const landingReducer = (state = initialState, action: any): LandingState => {
    switch (action.type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_LANDING:
            return {
                ...state,
                landing: action.Landing,
            };
        case SET_LANDINGS:
            return {
                ...state,
                landings: action.Landings,
                count: action.Landings.length,
                filteredLandings: action.Landings,
            };
        case CHANGE_DIRECTORY:
        case SET_RELOADING:
            return {
                ...state,
                landing: undefined,
                landings: [],
                count: 0,
                reloading: !state.reloading,
            };
        case SHOW_LANDING_FORM_FOR_CREATE:
            return {
                ...state,
                formType: "create",
                showForm: true,
            };
        case SHOW_LANDING_FORM_FOR_EDIT:
            return {
                ...state,
                formType: "edit",
                showForm: true,
                landing: action.Landing,
            };
        case HIDE_LANDING_FORM:
            return {
                ...state,
                landing: undefined,
                showForm: false
            };
        case FILTER_LANDINGS:
            return {
                ...state,
                filteredLandings: filterLandings(action.value, state.landings),
            };
        default:
            return state;
    }
};


const filterLandings = (value: string, landings: Landing[]) => {
    if (value === "") return landings;

    return landings.filter((item) => isFilterMatch(value, item));
};

const isFilterMatch = (value: string, landing: Landing) => {
    return landing.Name.toLowerCase().includes(value.toLowerCase());
};
