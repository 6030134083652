import { Office } from ".";

export const SET_OFFICE = "SET_OFFICE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_OFFICES = "SET_OFFICES";
export const HIDE_OFFICE_FORM = "HIDE_OFFICE_FORM";
export const SHOW_OFFICE_FORM_FOR_CREATE = "SHOW_OFFICE_FORM_FOR_CREATE";
export const SHOW_OFFICE_FORM_FOR_EDIT = "SHOW_OFFICE_FORM_FOR_EDIT";
export const FILTER_OFFICES = "FILTER_OFFICES";
export const SET_RELOADING = "SET_RELOADING";
export const SET_LANDING_OFFICES = "SET_LANDING_OFFICES";
export const SET_OFFICE_LOGO = "SET_OFFICE_LOGO";

export const setIsLoading = (isLoading: boolean) => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const setOffice = (office: Office | undefined) => ({
    type: SET_OFFICE,
    office,
});

export const setOffices = (offices: Office[]) => ({
    type: SET_OFFICES,
    offices,
});

export const hideOfficeForm = () => ({
    type: HIDE_OFFICE_FORM,
});

export const showOfficeFormForCreate = () => ({
    type: SHOW_OFFICE_FORM_FOR_CREATE,
});

export const showOfficeFormForEdit = (office: Office) => ({
    type: SHOW_OFFICE_FORM_FOR_EDIT,
    office,
});

export const filterOffices = (value: string) => ({
    type: FILTER_OFFICES,
    value,
});

export const reloading = () => ({
    type: SET_RELOADING,
});

export const setLandingOffices = (offices: Office[]) => ({
    type: SET_LANDING_OFFICES,
    offices
});

export const setOfficeLogo = (urlLogo: string) => ({
    type: SET_OFFICE_LOGO,
    urlLogo,
  });