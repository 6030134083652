import { ProviderAppointmentReason } from './../providerAppointmentReason/Types';
import { Provider, Picture } from "./Types";
import { Specialty } from "../specialty";
import { Location } from "../location";
import { ProviderEducationCourse } from "../providerEducationCourse";
import { ProviderEducationDegree } from "../providerEducationDegree";

export const ADD_PROVIDER_SPECIALTIES = "ADD_PROVIDER_SPECIALTIES";
export const ADD_PROVIDER_LOCATIONS = "ADD_PROVIDER_LOCATIONS";
export const ADD_PROVIDER_EDUCATION_COURSE = "ADD_PROVIDER_EDUCATION_COURSE";

export const ADD_PROVIDER_EDUCATION_DEGREE = "ADD_PROVIDER_EDUCATION_DEGREE";
export const ADD_PROVIDER_APPOINTMENT_REASON = "ADD_PROVIDER_APPOINTMENT_REASON";

export const CHANGE_PROVIDER_AVATAR = "CHANGE_PROVIDER_AVATAR";
export const LOADING_PROVIDERS = "LOADING_PROVIDERS";
export const LOADING_PROVIDER_LOCATIONS = "LOADING_PROVIDER_LOCATIONS";
export const LOADING_PROVIDER_EDUCATION_COURSE =
  "LOADING_PROVIDER_EDUCATION_COURSE";

export const LOADING_PROVIDER_EDUCATION_DEGREE =
  "LOADING_PROVIDER_EDUCATION_DEGREE";
export const LOADING_PROVIDER_APPOINTMENT_REASON =
  "LOADING_PROVIDER_APPOINTMENT_REASON";
export const LOADED_PROVIDER_APPOINTMENT_REASON =
  "LOADED_PROVIDER_APPOINTMENT_REASON";

export const PROVIDER_SAVED = "PROVIDER_SAVED";
export const SAVING_PROVIDER = "SAVING_PROVIDER";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const SET_PROVIDER_AVATAR = "SET_PROVIDER_AVATAR";
export const SET_PROVIDER_ADDITIONAL_PICTURE =
  "SET_PROVIDER_ADDITIONAL_PICTURE";
export const SET_PROVIDER_PICTURES = "SET_PROVIDER_PICTURES";
export const SET_PROVIDER_AVATAR_CAN_SAVE = "SET_PROVIDER_AVATAR_CAN_SAVE";
export const SET_PROVIDER_AVATAR_CAN_DELETE = "SET_PROVIDER_AVATAR_CAN_DELETE";
export const HIDE_PROVIDER_MODAL = "HIDE_PROVIDER_MODAL";
export const SHOW_PROVIDER_CREATE_MODAL = "SHOW_PROVIDER_CREATE_MODAL";
export const LOADING_PROVIDER = "LOADING_PROVIDER";
export const SET_PROVIDERS_CLINIC = "SET_PROVIDERS_CLINIC";
export const SET_PROVIDER_OFFICE = "SET_PROVIDER_OFFICE";

export const addProviderLocations = (locations: Location[]) => ({
  type: ADD_PROVIDER_LOCATIONS,
  locations,
});

export const addProviderSpecialties = (specialties: Specialty[]) => ({
  type: ADD_PROVIDER_SPECIALTIES,
  specialties,
});

export const changeProviderAvatar = (avatar: Picture | undefined) => {
  if (avatar && !avatar.IndAvatar) avatar.IndAvatar = true;

  return {
    type: CHANGE_PROVIDER_AVATAR,
    avatar,
  };
};

export const addProviderEducationCourse = (
  providerEducationCourse: ProviderEducationCourse[]
) => ({
  type: ADD_PROVIDER_EDUCATION_COURSE,
  providerEducationCourse,
});

export const addProviderEducationDegree = (
  providerEducationDegree: ProviderEducationDegree[]
) => ({
  type: ADD_PROVIDER_EDUCATION_DEGREE,
  providerEducationDegree,
});
export const loadingProviders = () => ({ type: LOADING_PROVIDERS });

export const loadingProviderLocations = () => ({
  type: LOADING_PROVIDER_LOCATIONS,
});

export const loadingProviderEducationCourse = () => ({
  type: LOADING_PROVIDER_EDUCATION_COURSE,
});

export const loadingProviderEducationDegree = () => ({
  type: LOADING_PROVIDER_EDUCATION_DEGREE,
});

export const providerSaved = () => ({ type: PROVIDER_SAVED });

export const savingProvider = () => ({ type: SAVING_PROVIDER });

export const setProvider = (provider: Provider | undefined) => ({
  type: SET_PROVIDER,
  provider,
});

export const setProviders = (providers: Provider[]) => ({
  type: SET_PROVIDERS,
  providers,
});

export const setNewProviderAvatar = (base64: string) => ({
  type: SET_PROVIDER_AVATAR,
  base64,
});

export const setProviderAdditionalPicture = (base64: string) => ({
  type: SET_PROVIDER_ADDITIONAL_PICTURE,
  base64,
});

export const setProviderPictures = (pictures: Picture[]) => ({
  type: SET_PROVIDER_PICTURES,
  pictures,
});

export const setProviderAvatarCanSave = (canSaveProviderAvatar: boolean) => ({
  type: SET_PROVIDER_AVATAR_CAN_SAVE,
  canSaveProviderAvatar,
});

export const setProviderAvatarCanDelete = (
  canDeleteProviderAvatar: boolean
) => ({
  type: SET_PROVIDER_AVATAR_CAN_DELETE,
  canDeleteProviderAvatar,
});

export const hideProviderModal = () => ({ type: HIDE_PROVIDER_MODAL });

export const showProviderCreateModal = () => ({
  type: SHOW_PROVIDER_CREATE_MODAL,
});

export const loadingProviderAppointmentReason = () => ({
  type: LOADING_PROVIDER_APPOINTMENT_REASON,
});

export const addProviderAppointmentReason = (
  providerAppointmentReason: ProviderAppointmentReason[]
) => ({
  type: ADD_PROVIDER_APPOINTMENT_REASON,
  providerAppointmentReason,
});

export const loadingProvider = () => ({ type: LOADING_PROVIDER });

export const setProvidersClinic = (providers: Provider[]) => ({
  type: SET_PROVIDERS_CLINIC,
  providers,
});

export const setProviderOffices = (providers: Provider[]) => ({
  type: SET_PROVIDER_OFFICE,
  providers,
});

