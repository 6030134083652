import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Transfer } from 'antd';
import { Trans } from "@lingui/macro";
import { LandingFormProps } from './Types';
import { Landing } from '../../store/landing/Types';
import { useDispatch, useSelector } from "react-redux";
import { getDirectoryTenantId, nameofFactory } from '../../utils';
import { hideLandingForm } from '../../store/landing';
import { OfficeState } from '../../store/offices';
import { loadLandingOffices } from '../../services/offices/OfficeService';
import { Link } from 'react-router-dom';

const LandingForm: React.FC<LandingFormProps> = (props) => {
    const dispatch = useDispatch();
    const { officeState } = useSelector(mapState);
    const { landingOffices } = officeState;
    const { landing, form, isLoading, formType, onSubmit } = props;
    const { getFieldDecorator, validateFields } = form;
    const nameof = nameofFactory<Landing>();

    const offices = (landingOffices ?? []).map((x) => ({
        key: x.OfficeID,
        title: x.Name,
    }));

    const linkedOffices = landing && landing.Offices
        ? landing.Offices.map(x => x.OfficeID)
        : [];

    const [targetKeys, setTargetKeys] = useState<string[]>(linkedOffices);

    const filterOption = (inputValue: any, data: any) =>
        data.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;

    useEffect(() => {
        loadLandingOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            back();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                const request = {
                    ...values,
                    LandingID: landing?.LandingID,
                    DirectoryTenantID: formType === "create" ? getDirectoryTenantId() : landing?.DirectoryTenantID,
                };

                onSubmit(request);
            }
        });
    };

    const handleChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const back = () => {
        dispatch(hideLandingForm());
    };

    return (
        <Row type="flex" justify="center">
            <Col xs={24} sm={18} md={12} lg={12} >
                <h2 style={{ textAlign: "center" }}>
                    {formType === 'edit' ? (
                        <Trans render="span" id="LandingForm.EditTitle">Edit Landing</Trans>
                    ) : (
                        <Trans render="span" id="LandingForm.CreateTitle">Create Landing</Trans>
                    )}
                </h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Item
                        label={<Trans render="span" id="LandingForm.NameLabel">Name</Trans>}
                    >
                        {getFieldDecorator(nameof("Name"), {
                            rules: [
                                { required: true, message: <Trans id="Common.RequiredFieldError" /> },
                            ],
                            initialValue: landing?.Name,
                        })(<Input maxLength={128} />)}
                    </Form.Item>
                    {formType === "edit" &&
                        <Row>
                            <Link
                                to={landing?.SID ? { pathname: landing?.LinkSlug } : '#'}
                                target={landing?.SID ? "_blank" : undefined}
                                style={{
                                    pointerEvents: landing?.SID ? 'auto' : 'none',
                                    color: landing?.SID ? 'primary' : 'gray',
                                    textDecoration: landing?.SID ? 'none' : 'inherit'
                                }}
                            >
                                <Trans id="Landing.Form.ViewProfileLink">
                                    View public profile
                                </Trans>
                            </Link>
                            {!landing?.SID && (
                                <p style={{ color: 'red', marginTop: '8px' }}>
                                    <Trans id="Landing.Form.NoSIDWarning">
                                        The link will only be enabled if it has an associated SID
                                    </Trans>
                                </p>
                            )}
                        </Row>
                    }
                    <Form.Item
                        label={<Trans render="span" id="LandingForm.UrlSlugLabel">URL Slug (Image)</Trans>}
                    >
                        {getFieldDecorator(nameof("UrlSlug"), {
                            rules: [
                                { required: true, message: <Trans id="Common.RequiredFieldError" /> },
                            ],
                            initialValue: landing?.UrlSlug,
                        })(<Input maxLength={75} />)}

                    </Form.Item>

                    <Form.Item
                        label={<Trans render="span" id="LandingForm.SIDLabel">SID</Trans>}
                    >
                        {getFieldDecorator(nameof("SID"), {
                            initialValue: landing?.SID,
                        })(<Input maxLength={36} />)}
                    </Form.Item>

                    {landing &&
                        <Form.Item>
                            {getFieldDecorator(nameof("IsDeleted"), {
                                valuePropName: 'checked',
                                initialValue: landing?.IsDeleted,
                            })(<Checkbox><Trans render="span" id="LandingForm.IsDeletedLabel">Is Deleted</Trans></Checkbox>)}
                        </Form.Item>
                    }

                    <Form.Item label={<Trans id="Offices.Form.Offices">Offices to associate</Trans>}>
                        {getFieldDecorator("Offices", {
                            valuePropName: "targetKeys",
                            initialValue: linkedOffices,
                        })(
                            <Transfer
                                className="transfer"
                                dataSource={offices}
                                showSearch
                                filterOption={filterOption}
                                render={(item) => item.title || ""}
                                style={{ textAlign: "center" }}
                                targetKeys={targetKeys}
                                onChange={handleChange}
                            />
                        )}
                    </Form.Item>

                    <Form.Item style={{ marginTop: "15px" }}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            {formType === 'edit' ? (
                                <Trans render="span" id="LandingForm.UpdateButton">Update</Trans>
                            ) : (
                                <Trans render="span" id="LandingForm.CreateButton">Create</Trans>
                            )}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="default"
                            style={{ width: '100%' }}
                            onClick={back}
                            loading={isLoading}
                        >
                            <Trans render="span" id="Common.Back">Back</Trans>
                        </Button>
                    </Form.Item>


                </Form>
            </Col>
        </Row>
    );
};


const mapState = (state: any): State => ({
    officeState: state.officeState,
});

interface State {
    officeState: OfficeState;
}

export default Form.create<LandingFormProps>()(LandingForm);
